import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'yuno-admin-routing',
    imports: [RouterModule],
    template: ` <router-outlet />`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutingComponent {}
