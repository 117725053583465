import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	inject
} from '@angular/core';
import { delay, tap } from 'rxjs';

import { AdminCookiesService, COOKIE_SEEN } from '@yuno/admin/core';
import { AuthFacade } from '@yuno/angular-auth';

@Component({
	selector: 'yuno-admin-features-cookie-message',
	imports: [AsyncPipe],
	template: `
		@if ({ data: data$ | async }; as data) {
			@if (!messageSeen) {
				<div
					class="absolute bottom-0 left-0 z-100 flex w-full flex-row items-center justify-center px-2 sm:px-4 md:px-8">
					<div
						class="relative z-100 w-full max-w-full rounded-t-lg bg-yuno-gray-700 px-4 py-3 pr-6 text-xs text-yuno-gray-200 xl:max-w-5xl">
						The YUNO Project Atlas CMS only utilizes functional cookies, which serve to
						improve the performance of the application without gathering personally
						identifiable information.
						<div class="absolute right-0 top-0 max-w-full cursor-pointer xl:max-w-5xl">
							<svg
								(click)="messageSeen = true"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 40 40"
								class="m-1 h-5 w-5"
								fill="currentColor">
								<path
									d="M28.2 29.2c-.3 0-.5-.1-.7-.3L11 12.4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l16.5 16.5c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3z" />
								<path
									d="M11.8 29.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L27.6 11c.4-.4 1-.4 1.4 0s.4 1 0 1.4L12.5 28.9c-.2.2-.4.3-.7.3z" />
							</svg>
						</div>
					</div>
				</div>
			}
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminFeaturesCookieMessageComponent implements OnInit {
	private readonly cookies = inject(AdminCookiesService);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly auth = inject(AuthFacade);

	data$ = this.auth.isAuthenticated$.pipe(
		delay(500),
		tap(() => this.checkCookieMessage())
	);
	messageSeen = false;

	ngOnInit(): void {
		this.checkCookieMessage();
	}

	checkCookieMessage(): void {
		this.messageSeen = !!this.cookies.getCookie(COOKIE_SEEN);
		this.cdr.markForCheck();
	}
}
