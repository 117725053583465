/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ENV_TYPE } from '@yuno/project-atlas/core';



import { globalEnvironment } from '../../../../_environments/environment.global';


declare let require: any;
export const environment: ENV_TYPE = {
	...globalEnvironment,
	// only in use in development
	'yuno-client-name': 'rws',
	// only in use in development AND when testing the Header resolver
	'yuno-app-name': window.location.pathname.replace(/^\/([^/]*).*$/, '$1')
	// 'yuno-app-name': 'a39'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
